// store.js

import * as Sentry from '@sentry/vue';
import { Capacitor } from '@capacitor/core';
import moment from 'moment/moment';

import { createStore } from 'vuex';
import {
  Timestamp,
  FirebaseAuthentication,
  signOut,
  auth,
} from "@/firebaseConfig";
import { removeAllListeners } from "@/firebaseListeners.js";
import { ApiRequest, SendSuccess, SendError, } from "@/helpers.js";

const store = createStore({
  modules: {
  },
  state: {
    drawer: true,
    currentTime: moment(),
    query: {},
    appLoaded: false,
    isLoading: false,
    isLoadingUploading: false,
    isLoggingIn: false,
    currentUser: null,
    sessions: [],
    messages: {},
    servers: [],
    userProfile: {},
    pricing: null,
    recordingWidget: false,
    recordStatus: 'NONE', // "RECORDING" | "PAUSED"
  },
  actions: {
    // Auth
    setQuery({ commit }, data) {
      commit('setQuery', data.query);
    },
    // Auth
    clearData({ commit }) {
      commit('setCurrentUser', null);
      commit('setUserProfile', {});
      commit('setSessions', []);
      commit('setServers', []);
    },
    check({ commit, state }, data) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiRequest({
            method: 'get',
            url: `/check`,
            params: {
              version: import.meta.env.VITE_VERSION,
              platform: 'web',
            }
          });
          resolve(response);
        } catch(err) {
          reject(err);
        }
      });
    },
    logout({ commit, dispatch }) {
      return new Promise(async (resolve, reject) => {
        try {
          await removeAllListeners();
          if (Capacitor.isNativePlatform()) {
            await FirebaseAuthentication.signOut();
          }
          await signOut(auth);
          dispatch('clearData');
          Sentry.setUser(null);
          SendSuccess('Logged Out');
          resolve();
        } catch(err) {
          reject(err);
        }
      });
    },
    getUserProfile({ commit, state }, data) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiRequest({
            method: 'get',
            url: `/user`,
            params: {
              userId: data.userId,
            }
          });
          resolve(response);
        } catch(err) {
          reject(err);
        }
      });
    },
    updateUserProfile({ commit, state }, data) {
      return new Promise(async (resolve, reject) => {
        try {
          data.updatedAt = Timestamp.now();
          const response = await ApiRequest({
            method: 'put',
            url: `/user`,
            body: data,
          });
          resolve(response);
        } catch(err) {
          reject(err);
        }
      });
    },
    uploadUserAudio({ commit, state }, data) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiRequest({
            method: 'post',
            url: `/user/audio`,
            body: data,
          });
          resolve(response);
        } catch(err) {
          reject(err);
        }
      });
    },
    checkUsername({ commit, state }, data) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiRequest({
            method: 'get',
            url: `/user/username`,
            params: {
              username: data.username,
            },
          });
          resolve(response);
        } catch(err) {
          reject(err);
        }
      });
    },
    // Sessions
    createSession({ commit, state }, data) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiRequest({
            method: 'post',
            url: `/session`,
            body: data,
          });
          // Listener will update this, but doing this is faster
          commit('setSessions', [{
            sessionId: response.sessionId,
            createdAt: Timestamp.now(),
          }].concat(state.sessions));
          resolve(response);
        } catch(err) {
          reject(err);
        }
      });
    },
    deleteSession({ commit, state }, data) {
      return new Promise(async (resolve, reject) => {
        try {
          if (!data.sessionId) {
            return reject('Invalid sessionId');
          }
          const response = await ApiRequest({
            method: 'delete',
            url: `/session`,
            params: {
              sessionId: data.sessionId
            }
          });
          // Listener will update this, but doing this is faster
          commit('setSessions', state.sessions.filter(s => s.sessionId !== data.sessionId));
          resolve(response);
        } catch (err) {
          reject(err);
        }
      });
    },
    // Servers
    checkServer({ commit, state }, data) {
      return new Promise(async (resolve, reject) => {
        try {
          if (!data.serverId) {
            return reject('Invalid serverId');
          }
          const response = await ApiRequest({
            method: 'get',
            url: `/server/${data.serverId}`,
          });
          resolve(response);
        } catch(err) {
          reject(err);
        }
      });
    },
    createServer({ commit, state, dispatch }, data) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiRequest({
            method: 'post',
            url: `/server`,
          });
          resolve(response);
        } catch(err) {
          reject(err);
        }
      });
    },
    deleteServers({ commit, state, dispatch }, data) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiRequest({
            method: 'delete',
            url: `/server`,
          });
          resolve(response);
        } catch(err) {
          reject(err);
        }
      });
    },
    // Request
    sendLiveRequest({ commit, state, dispatch }, data) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiRequest({
            method: 'post',
            url: `/request/live`,
            body: data,
          });
          resolve(response);
        } catch(err) {
          reject(err);
        }
      });
    },
    deleteLiveRequest({ commit, state, dispatch }, data) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiRequest({
            method: 'delete',
            url: `/request/live`,
          });
          resolve(response);
        } catch(err) {
          reject(err);
        }
      });
    },
    // Payments
    getPricing({ commit, state }, data) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiRequest({
            method: 'get',
            url: `/payment/pricing`,
          });
          resolve(response);
          commit('setPricing', response.pricing);
        } catch(err) {
          reject(err);
        }
      });
    },
    createPayment({ commit, state }, data) {
      return new Promise(async (resolve, reject) => {
        try {
          if (!data.priceId) {
            return reject('Invalid priceId');
          }
          const response = await ApiRequest({
            method: 'get',
            url: `/payment/stripe/checkout`,
            params: {
              priceId: data.priceId
            }
          });
          resolve(response);
        } catch(err) {
          reject(err);
        }
      });
    },
    stripePortal({ commit, state }, data) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiRequest({
            method: 'get',
            url: `/payment/stripe/portal`,
          });
          resolve(response);
        } catch(err) {
          reject(err);
        }
      });
    },
  },
  getters: {
    allServers(state) {
      return state.servers.map(s => {
        let duration = moment.duration(state.currentTime.diff(moment.unix(s.createdAt.seconds)));
        if (s.status === 'stopped') {
          duration = moment.duration(moment.unix(s.stoppedAt.seconds).diff(moment.unix(s.createdAt.seconds)));
        }
        const hours = Math.floor(duration.asHours());
        const minutes = Math.floor(duration.asMinutes()) - hours * 60;
        const seconds = Math.floor(duration.asSeconds()) - hours * 3600 - minutes * 60;
        return {
          serverId: s.serverId,
          status: s.status,
          message: s.message,
          duration: `${hours}h, ${minutes}m, ${seconds}s`,
        };
      });
    },
    activeServers(state) {
      return state.servers.filter(server => server.status === 'active').map(s => {
        const duration = moment.duration(state.currentTime.diff(moment.unix(s.createdAt.seconds)));
        const hours = Math.floor(duration.asHours());
        const minutes = Math.floor(duration.asMinutes()) - hours * 60;
        const seconds = Math.floor(duration.asSeconds()) - hours * 3600 - minutes * 60;
        return {
          serverId: s.serverId,
          status: s.status,
          message: s.message,
          duration: `${hours}h, ${minutes}m, ${seconds}s`,
        };
      });
    },
    activePendingServers(state) {
      return state.servers.filter(s => ['active', 'pending'].includes(s.status)).map(s => {
        const duration = moment.duration(state.currentTime.diff(moment.unix(s.createdAt.seconds)));
        const hours = Math.floor(duration.asHours());
        const minutes = Math.floor(duration.asMinutes()) - hours * 60;
        const seconds = Math.floor(duration.asSeconds()) - hours * 3600 - minutes * 60;
        return {
          serverId: s.serverId,
          status: s.status,
          message: s.message,
          duration: `${hours}h, ${minutes}m, ${seconds}s`,
        };
      });
    },
  },
  mutations: {
    setCurrentTime(state, val) {
      state.currentTime = val;
    },
    setQuery(state, val) {
      state.query = val;
    },
    appLoaded(state, val) {
      state.appLoaded = val;
    },
    isLoading(state, val) {
      state.isLoading = val;
    },
    isLoadingUploading(state, val) {
      state.isLoadingUploading = val;
    },
    isLoggingIn(state, val) {
      state.isLoggingIn = val;
    },
    setCurrentUser(state, val) {
      state.currentUser = val;
    },
    setSessions(state, val) {
      state.sessions = val;
    },
    setMessages(state, val) {
      state.messages[val.sessionId] = val.messages;
    },
    setUserProfile(state, val) {
      state.userProfile = val;
    },
    setServers(state, val) {
      state.servers = val;
    },
    setPricing(state, val) {
      state.pricing = val;
    },
    setRecordingWidget(state, val) {
      state.recordingWidget = val;
    },
    setRecordStatus(state, val) {
      state.recordStatus = val;
    },
    setDrawer(state, val) {
      state.drawer = val;
    },
  },
});

export default store;
