<template>
  <q-header class="header-bar bg-transparent"
            :reveal-offset="100">

    <!-- :class="isDarkMode ? 'bg-dark' : 'bg-light'" -->

    <div class="rounded-borders header-bar-inner q-ma-sm bg-glass">
      <q-tabs align="left" v-if="goBack">
        <q-btn class="q-ml-sm q-mr-sm bg-grey text-dark" size="sm" rounded unelevated
               :to="goBack" icon="mdi-arrow-left">
          <template v-slot:default>
            <q-tooltip anchor="top left" self="center start" :offset="[-15, -50]">
              Back
            </q-tooltip>
          </template>
        </q-btn>
      </q-tabs>

      <q-item class="text-secondary bg-accent rounded-borders" :to="`/`" :style="`min-width:${isMobile ? 155 : 240}px;`">
        <q-item-section class="q-ma-none">
          <span class="title text-h5 text-bold" :class="isDarkMode ? 'linear-wipe-white' : 'linear-wipe-dark'">
            {{ isMobile ? '⚔️🌙' : 'Sword Moon' }}
          </span>
        </q-item-section>
        <q-item-section side class="q-ma-none">
          <q-badge class="bg-secondary">alpha</q-badge>
        </q-item-section>
      </q-item>

      <q-tabs align="left" v-if="currentUser">
        <q-btn class="text-white q-ml-sm" size="md" rounded unelevated
               color="secondary"
               @click="toggleDrawer(false)"
               v-if="path.includes('/s')">
          <q-icon :name="drawer ? 'mdi-backburger' : 'mdi-menu'"></q-icon>
        </q-btn>
        <q-btn class="q-ml-sm text-dark bg-accent" size="md" :to="`/s`" rounded unelevated
               icon="mdi-text-account" @click="openDrawer"
              v-else>
        </q-btn>
      </q-tabs>

      <q-space />

      <div ref="header-center" class="full-width">
        <div ref="header-title" class="q-ma-none cursor-pointer absolute-center ellipsis"
            :style="`max-width: ${maxWidthTitle}px`"
            v-if="title && !isMobile" @click="check">
          {{ title }}
        </div>
      </div>

      <q-space />

      <q-tabs align="right" v-if="currentUser">

        <Minutes class="q-mr-sm" v-if="!isMobile"></Minutes>

        <q-btn-dropdown class="q-mr-sm text-dark bg-accent" size="md"
                        rounded unelevated
                        dropdown-icon='mdi-cog-outline'>
          <template v-slot:label>
            <q-tooltip anchor="top left" self="center start" :offset="[-15, -50]">
              Settings
            </q-tooltip>
          </template>

          <div class="row no-wrap q-pa-md">
            <div class="column">
              <Minutes class="q-mb-sm" v-if="isMobile"></Minutes>

              <q-btn name="settings" class="bg-white text-dark text-left" to="/settings/account" size="md"
                     rounded unelevated
                     @click="toggleDrawer(true)"
                     align="left"
                     label="Settings"
                     icon="mdi-note-multiple-outline">
              </q-btn>
              <q-btn name="payments" class="bg-green text-white q-mt-sm" rounded unelevated size="md"
                     @click="openPaymentModal"
                     align="left"
                     label="Buy Minutes"
                     icon="mdi-currency-usd">
              </q-btn>
              <q-btn class="q-mt-sm" unelevated rounded color="primary" text-color="white" size="md"
                     type="button" @click="toggleDarkMode"
                     align="left"
                     :icon="isDarkMode ? 'mdi-weather-sunny' : 'mdi-moon-waning-crescent'"
                     :label="isDarkMode ? 'Light Mode' : 'Dark Mode'">
              </q-btn>
            </div>

            <q-separator vertical inset class="q-mx-lg" />

            <div class="column items-center cursor-pointer" v-on:click="navigateToProfile">
              <ProfilePicture :editable="false" :size="100"></ProfilePicture>
              <!--
              <q-avatar size="60px">
                <img :alt="userProfile.displayName" :src="userProfile.photoURL || '/images/default.png'" />
              </q-avatar>
              -->
              <div class="text-subtitle1 q-mt-md q-mb-xs">{{userProfile.name}}</div>
            </div>
          </div>
        </q-btn-dropdown>

        <!--<q-btn v-if="!isMobile" class="q-mr-sm bg-green text-light" size="md" @click="openPaymentModal" rounded unelevated>
          <template v-slot:default>
            <q-icon name="mdi-currency-usd"></q-icon>
            <q-tooltip anchor="top left" self="center start" :offset="[-15, -50]">
              Payment
            </q-tooltip>
          </template>
        </q-btn>-->

      </q-tabs>

      <q-tabs align="right" v-else-if="!['/login', '/signup', '/reset-password'].includes(path)">
        <q-btn class="q-mr-sm text-white bg-secondary" size="md" :to="`/login`" icon="mdi-arrow-top-right" rounded unelevated style="width:140px;">
          Get Started
        </q-btn>
      </q-tabs>
    </div>

  </q-header>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import { Dialog } from "quasar";

import PaymentModal from "@/components/modals/PaymentModal.vue";
import ProfilePicture from "@/components/blocks/ProfilePicture.vue";
import Minutes from "@/components/blocks/Minutes.vue";

import { SendError, SendSuccess } from "@/helpers.js";

export default {
  components: {
    ProfilePicture,
    Minutes,
  },
  props: {
    title: String,
    goBack: String,
  },
  computed: {
    ...mapState([
      'appLoaded',
      'isLoading',
      'currentUser',
      'userProfile',
      'servers',
      'drawer',
    ]),
    ...mapGetters([
      'activeServers',
      'activePendingServers',
    ]),
    isDarkMode() {
      return this.$q.dark.isActive;
    },
    isMobile() {
      return (window.innerWidth < 1024);
    },
  },
  async beforeCreate() {
  },
  async beforeMount() {
  },
  mounted() {
    this.setupMaxWidthTitle();
  },
  unmounted() {
    clearInterval(this.interval); // Clean up
  },
  data() {
    return {
      moment,
      interval: null,
      env: import.meta.env.VITE_NODE_ENV,
      path: this.$route.path,
      maxWidthTitle: 100,
    };
  },
  methods: {
    toggleDarkMode() {
      this.$q.dark.toggle();
      this.$cookies.set('darkmode', this.$q.dark.isActive);
    },
    toggleDrawer(force) {
      this.$store.commit('setDrawer', force ? true : !this.drawer);
    },
    async setupMaxWidthTitle() {
      if (this.$refs['header-center'] && this.$refs['header-title']) {
        if (this.$refs['header-title'].scrollWidth > this.$refs['header-center'].scrollWidth) {
          this.maxWidthTitle = this.$refs['header-center'].scrollWidth;
        } else {
          this.maxWidthTitle = this.$refs['header-center'].scrollWidth
              - this.$refs['header-title'].getBoundingClientRect().x / 4;
        }
      }
    },
    async check() {
      try {
        const response = await this.$store.dispatch('check');
        SendSuccess(response.message);
      } catch(err) {
        SendError(err);
      }
    },
    openPaymentModal() {
      this.$q.dialog({
        component: PaymentModal,
        componentProps: { }
      }).onOk(() => {
        console.log('OK');
      }).onCancel(() => {
        console.log('Cancel');
      }).onDismiss(() => {
        console.log('Dismiss: OK/Cancel');
      });
    },
    navigateToProfile() {
      this.$router.push('/profile');
    },
    openDrawer() {
      this.$store.commit('setDrawer', true);
    },
  },
  watch: {
  },
};
</script>

<style scoped lang="scss">
.header-bar {
  height: 60px;
}
.header-bar-inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}
</style>