<template>
  <section>
    <HeaderNav></HeaderNav>

    <q-page-container class="relative-position">

      <SessionDrawer v-if="currentUser && ['Chat', 'ChatSession'].includes(name)"></SessionDrawer>

      <Home v-if="name === 'Home'"></Home>
      <Chat v-if="['Chat', 'ChatSession'].includes(name)"></Chat>
      <Profile v-if="name === 'Profile'"></Profile>
      <Stream v-if="name === 'Stream'"></Stream>

    </q-page-container>

  </section>
</template>

<script>
import { mapState } from "vuex";

import HeaderNav from '@/components/blocks/HeaderNav.vue';
import SessionDrawer from "@/components/blocks/SessionDrawer.vue";
import Home from '@/components/pages/Home.vue';
import Chat from "@/components/pages/Chat.vue";
import Profile from "@/components/pages/Profile.vue";
import Stream from "@/components/pages/Stream.vue";

export default {
  head() {
    return {
      title: 'Sword Moon',
      titleTemplate: (title) => `${title} | Sword Moon`,
      meta: [
        { name: 'robots', content: 'noindex' },
      ],
    };
  },
  components: {
    HeaderNav,
    SessionDrawer,
    Home,
    Chat,
    Profile,
    Stream,
  },
  async beforeMount() {
  },
  async mounted() {
  },
  data() {
    return {
      option: null,
      name: this.$route.name,
    };
  },
  computed: {
    ...mapState([
      'userProfile',
      'currentUser',
    ]),
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
</style>
