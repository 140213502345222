<template>
  <section v-if="option">

    <HeaderNav :title="title"></HeaderNav>

    <q-page-container class="relative-position">

      <SettingsDrawer></SettingsDrawer>

      <section class="q-custom-content" v-if="option === 'account'">
        <div class="page-container is-fullwidth">
          <h2 class="q-pl-md q-my-none">Account</h2>
          <p class="q-pl-md q-pr-md q-pb-md">Manage your Sword Moon profile. <a class="text-underline text-accent" href="#" target="_blank">Learn More</a></p>
          <div class="settings-separator"></div>
          <q-card class="q-pa-md q-ma-md bg-glass">
            <q-item>
              <q-item-section>

                <q-form @submit="updateUser" class="q-gutter-y-md">

                  <h5 class="q-my-sm">AI Settings</h5>

                  <div class="row q-col-gutter-sm q-mb-sm">
                    <div class="col-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                      <q-select
                          outlined
                          class="full-width" style="flex-shrink:1"
                          v-model="sttModel"
                          :options="sttModels"
                          label="STT"
                          option-value="value"
                          option-label="name"
                          emit-value>
                        <template v-slot:selected>
                          {{ sttModels.find((model) => model.value === sttModel)?.name }}
                        </template>
                      </q-select>
                    </div>
                    <div class="col-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                      <q-select
                          outlined
                          class="full-width" style="flex-shrink:1"
                          v-model="llmModel"
                          :options="llmModels"
                          label="LLM"
                          option-value="value"
                          option-label="name"
                          emit-value>
                        <template v-slot:selected>
                          {{ llmModels.find((model) => model.value === llmModel)?.name }}
                        </template>
                      </q-select>
                    </div>
                    <div class="col-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                      <q-select
                          outlined
                          class="full-width" style="flex-shrink:1"
                          v-model="ttsModel"
                          :options="ttsModels"
                          label="TTS"
                          option-value="value"
                          option-label="name"
                          emit-value>
                        <template v-slot:selected>
                          {{ ttsModels.find((model) => model.value === ttsModel)?.name }}
                        </template>
                      </q-select>
                    </div>
                  </div>

                  <q-btn
                      unelevated
                      class="full-width"
                      rounded
                      color="accent"
                      text-color="white"
                      type="submit"
                      :disabled="isLoading">
                    Save
                  </q-btn>
                </q-form>

              </q-item-section>
            </q-item>
          </q-card>
        </div>
      </section>

      <section class="q-custom-content" v-if="option === 'notifications'">
        <div class="page-container is-fullwidth">
          <h2 class="q-pl-md q-my-none">Notifications</h2>
          <p class="q-pl-md q-pr-md q-pb-md">Manage your Sword Moon notifications. <router-link class="text-underline text-accent" :to="'/'">Learn More</router-link></p>
          <div class="settings-separator"></div>
          <q-card class="q-pa-md q-ma-md bg-glass">
            <q-item>
              <q-item-section>
                <span class="text-weight-light">Nothing to show.</span>
              </q-item-section>
            </q-item>
          </q-card>
        </div>
      </section>

      <section class="q-custom-content" v-if="option === 'servers'">
        <div class="page-container is-fullwidth">
          <h2 class="q-pl-md q-my-none">Servers</h2>
          <p class="q-pl-md q-pr-md q-pb-md">Manage your Sword Moon servers. <router-link class="text-underline text-accent" :to="'/'">Learn More</router-link></p>
          <div class="settings-separator"></div>
          <q-card class="q-pa-md q-ma-md bg-glass">
            <q-item v-if="allServers.length === 0">
              <q-item-section>
                <span class="text-weight-light">No Servers have been created yet.</span>
              </q-item-section>
            </q-item>
            <div v-for="(server, index) in allServers" :key="index">
              <q-item class="rounded-borders q-mb-xs" :class="isDarkMode ? 'bg-dark' : 'bg-light'">
                <q-item-section class="cursor-pointer" side @click="checkServer(server)">
                  <q-badge :class="server.status === 'active' ? 'bg-green' : (server.status === 'pending' ? 'bg-yellow' : 'bg-negative')"></q-badge>
                </q-item-section>
                <q-item-section side>
                  Server {{ server.status === 'active' ? 'Online' : (server.status === 'pending' ? 'Pending' : 'Stopped') }}
                </q-item-section>
                <q-item-section>
                </q-item-section>
                <q-item-section side
                                style="width:180px;"
                                :class="server.status === 'active' ? 'text-green' : (server.status === 'pending' ? 'text-yellow' : 'text-negative')">
                  {{server.duration}}
                </q-item-section>
              </q-item>
              <!-- <p class="q-ml-md text-weight-light text-subtitle2" v-if="server.message">{{ server.message }}</p> -->
            </div>
          </q-card>
        </div>
      </section>

      <section class="q-custom-content" v-if="option === 'payments'">
        <div class="page-container is-fullwidth">
          <h2 class="q-pl-md q-my-none">Payments</h2>
          <p class="q-pl-md q-pr-md q-pb-md">Manage your Sword Moon payments. <router-link class="text-underline text-accent" :to="'/'">Learn More</router-link></p>
          <div class="settings-separator"></div>
          <q-card class="q-pa-md q-ma-md bg-glass">
            <q-item>
              <q-item-section>
                <q-btn class="q-mb-sm" @click="openPaymentModal"
                       unelevated rounded color="green" text-color="white" type="button">
                  Buy Minutes
                </q-btn>
                <q-btn @click="routeToStripePortal" v-if="userProfile.customerId"
                       unelevated rounded color="accent" text-color="white" type="button">
                  View Past Payments
                </q-btn>
              </q-item-section>
            </q-item>
          </q-card>
        </div>
      </section>

    </q-page-container>

    <FooterNav></FooterNav>

  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from 'moment';

import HeaderNav from '@/components/blocks/HeaderNav.vue';
import SettingsDrawer from "@/components/blocks/SettingsDrawer.vue";
import FooterNav from "@/components/blocks/FooterNav.vue";
import { SendError, SendSuccess } from "@/helpers";
import PaymentModal from "@/components/modals/PaymentModal.vue";

export default {
  head() {
    return {
      title: this.title,
      titleTemplate: (title) => `${title} | Sword Moon`,
      meta: [
        { name: 'robots', content: 'noindex' },
      ],
    };
  },
  components: {
    FooterNav,
    SettingsDrawer,
    HeaderNav,
  },
  async beforeMount() {
    if (!this.options.includes(this.$route.params.option)) {
      this.$router.push('/404');
    } else {
      this.option = this.$route.params.option;
    }
  },
  mounted() {
    this.setDefaults();
  },
  unmounted() {
    clearInterval(this.interval); // Clean up
  },
  data() {
    return {
      option: null,
      options: [
        'account',
        'notifications',
        'servers',
        'payments',
      ],
      llmModel: '',
      llmModels: [
        { name: 'Llama 3', value: 'llama3:8b' },
        { name: 'Command R', value: 'command-r:35b' },
        { name: 'Gemma', value: 'gemma' },
        { name: 'Phi-3', value: 'phi3' },
        { name: 'GPT-4o', value: 'gpt-4o' },
        { name: 'Llama 3 (Groq)', value: 'llama3-70b-8192' },
      ],
      ttsModel: '',
      ttsModels: [
        { name: 'Voicecraft', value: 'voicecraft' },
        { name: 'XTTS v2', value: 'xtts' },
        { name: 'OpenVoice', value: 'openvoice' },
        { name: 'Deepgram', value: 'deepgram' },
      ],
      sttModel: '',
      sttModels: [
        { name: 'Whisper', value: 'whisper' },
        { name: 'Deepgram', value: 'deepgram' },
      ],
    };
  },
  computed: {
    ...mapState([
      'userProfile',
      'currentUser',
      'servers',
    ]),
    ...mapGetters([
      'allServers',
    ]),
    title() {
      return this.$route.name;
    },
    isDarkMode() {
      return this.$q.dark.isActive;
    },
  },
  methods: {
    async setDefaults() {
      if (this.userProfile.llmModel) {
        this.llmModel = this.userProfile.llmModel;
      }
      if (this.userProfile.ttsModel) {
        this.ttsModel = this.userProfile.ttsModel;
      }
      if (this.userProfile.sttModel) {
        this.sttModel = this.userProfile.sttModel;
      }
      this.isLoading = false;
    },
    async updateUser() {
      try {
        this.isLoading = true;
        await this.$store.dispatch('updateUserProfile', {
          llmModel: this.llmModel,
          ttsModel: this.ttsModel,
          sttModel: this.sttModel,
        });
        SendSuccess(`Successfully Updated Profile`);
        setTimeout(() => this.setDefaults(), 100);
      } catch (err) {
        SendError(err);
      }
      this.isLoading = false;
    },
    async checkServer(server) {
      try {
        const response = await this.$store.dispatch('checkServer', server);
        SendSuccess(response.message);
      } catch(err) {
        SendError(err);
      }
    },
    async routeToStripePortal() {
      try {
        const response = await this.$store.dispatch('stripePortal');
        if (response.url) {
          window.location.href = response.url;
        }
      } catch(err) {
        SendError(err);
      }
    },
    openPaymentModal() {
      this.$q.dialog({
        component: PaymentModal,
        componentProps: { }
      }).onOk(() => {
        console.log('OK');
      }).onCancel(() => {
        console.log('Cancel');
      }).onDismiss(() => {
        console.log('Dismiss: OK/Cancel');
      });
    },
  },
  watch: {
    userProfile: {
      handler(newItem, oldItem) {
        this.setDefaults();
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.settings-separator {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
