<template>
  <q-dialog maximized>
    <q-card class="text-center flex column full-height">
      <q-card-section class="flex">
        <q-btn v-close-popup >Close</q-btn>
      </q-card-section>
      <q-card-section
        class="q-gutter-y-md flex column flex-center col q-px-auto"
      >
        <div class="text-weight-medium text-uppercase text-h6">Say the following and hit stop:</div>

        <div>
          Quick foxes in snazzy hats vault over lazy dogs, as quirky wizards bewitch befuddled gods.
        </div>

        <q-separator color="grey-3" class="full-width q-mx-auto" />

        <Recorder
          :recordStatus="recordStatus"
          :toggleRecording="toggleRecording"
          :togglePauseRecording="togglePauseRecording"
          :recordDuration="recordDuration"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent } from 'quasar';

import { mapState, mapGetters } from 'vuex';
import { VoiceRecorderWrapper } from '@/classes/VoiceRecorderWrapper';
import { SendError } from '@/helpers.js';

import SpeechRecordSaveModal from '@/components/modals/SpeechRecordSaveModal.vue';
import Recorder from '@/components/blocks/Recorder.vue';
// import { useLocalNotification } from '@/hooks/useLocalNotification';

export default {
  components: {
    Recorder,
  },
  data() {
    return {
      recorder: new VoiceRecorderWrapper((state) =>
        this.$store.commit('setRecordStatus', state)
      ),
      env: import.meta.env.VITE_NODE_ENV,
      recordDuration: 0,
      recordDurationInterval: null,
      localNotifications: {
        removeListener: null,
        sendNotification: null,
      },
    };
  },
  props: {
  },
  emits: [
    ...useDialogPluginComponent.emits,
  ],
  computed: {
    ...mapState([
      'recordingWidget',
      'recordStatus'
    ]),
  },
  async mounted() {
    const status = await VoiceRecorderWrapper.getCurrentStatus();
    this.$store.commit('setRecordStatus', status);

    /*
    if (Capacitor.getPlatform() === 'ios') {
      const { sendNotification, addListener } = useLocalNotification(
        () => this.recorder.resumeRecording(),
        {
          title: 'Recording Paused',
          body: 'Tap to resume recording',
          actionTypeId: 'resumeRecording',
        }
      );

      const removeListener = await addListener();
      this.localNotifications = { removeListener, sendNotification };
      this.startCallObserver();
    } */
  },
  async unmounted() {
    const status = await VoiceRecorderWrapper.getCurrentStatus();
    if (status === 'RECORDING') {
      await this.stopRecording();
    }
    /*
    if (Capacitor.getPlatform() === 'ios') {
      this.localNotifications.removeListener();
    } */
  },
  methods: {
    onDialogHide() {
      this.$emit('hide');
    },
    onOKClick() {
      this.$emit('ok', {});
      this.onDialogHide();
    },
    onCancelClick() {
      this.$emit('hide');
    },
    //
    async toggleRecording(forceStop) {
      try {
        if (this.recordStatus !== 'RECORDING' && !forceStop) {
          await this.recorder.startRecording();
        } else if (this.recordStatus !== 'NONE') {
          await this.stopRecording();
        }
      } catch (err) {
        SendError(err);
        console.error(err);
      }
    },
    openSpeechSampleSaveDialog(audioURL, duration) {
      this.$q.dialog({
        component: SpeechRecordSaveModal,
        componentProps: {
          audioURL,
          duration,
        },
      }).onOk(() => {
        this.onOKClick();
      }).onCancel(() => {
        console.log('Cancel');
      }).onDismiss(() => {
        console.log('Dismiss: OK/Cancel');
      });
    },
    async stopRecording() {
      const { audioURL, duration } = await this.recorder.stopRecording();

      const minRecordDuration = 5000;
      if (duration < minRecordDuration) {
        SendError('Recording too short');
      } else {
        this.openSpeechSampleSaveDialog(audioURL, duration);
      }
    },
    async togglePauseRecording() {
      try {
        if (this.recordStatus === 'RECORDING') {
          await this.recorder.pauseRecording();
        } else if (this.recordStatus === 'PAUSED') {
          await this.recorder.resumeRecording();
        }
      } catch (error) {
        SendError(error);
        console.error(error);
      }
    },
    /*
    async startCallObserver() {
      CallDetector.addListener('outgoingCall', (data) => {
        if (this.recordStatus === 'RECORDING') {
          this.recorder.pauseRecording();
        }
      });

      CallDetector.addListener('callEnded', async (data) => {
        if (this.recordStatus === 'PAUSED') {
          this.localNotifications.sendNotification();
        }
      });

      CallDetector.addListener('incomingCall', (data) => {
        if (this.recordStatus === 'RECORDING') {
          this.recorder.pauseRecording();
        }
      });
    },*/
  },
  watch: {
    recordStatus: {
      handler(newStatus, oldItem) {
        if (newStatus === 'RECORDING') {
          this.recordStartTime = new Date() - this.recordDuration * 1000;
          this.recordDurationInterval = setInterval(() => {
            const now = new Date();
            const elapsedMilliseconds = now - this.recordStartTime;
            this.recordDuration = Math.floor(elapsedMilliseconds / 1000);
          }, 1000);
        } else if (newStatus === 'PAUSED') {
          clearInterval(this.recordDurationInterval);
        } else {
          clearInterval(this.recordDurationInterval);
          this.recordDuration = 0;
        }
      },
    },
  },
};
</script>
