<template>
  <q-btn class="border-light bg-transparent text-light relative-position" rounded unelevated
         :style="`width:180px;line-height:22px;`"
         size="md"
         @click="toggleServer">
    <q-tooltip anchor="top left" self="center start" :offset="[-15, -50]">
      <span v-if="activePendingServers.length > 0 && activePendingServers[0].status === 'pending'">Session Is Starting Please Wait</span>
      <span v-else-if="activePendingServers.length > 0">Session Is Running</span>
      <span v-else>Session Is Not Running</span>
    </q-tooltip>
    <div v-if="activePendingServers.length && activePendingServers[0].status === 'active'">
      <q-spinner-rings style="right:-6px;top:-6px;position:absolute;" color="green" size="md"></q-spinner-rings>
    </div>
    <span v-if="activePendingServers.length > 0 && activePendingServers[0].status === 'pending'">
      <q-spinner-tail class="q-mr-sm" style="width:16px;height:16px;"></q-spinner-tail> Starting...
    </span>
    <span v-else-if="activePendingServers.length > 0">{{`${userProfile.minutes || 0 } mins remain`}}</span>
    <span v-else>{{`${userProfile.minutes || 0 } mins available`}} </span>
  </q-btn>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { SendError, SendSuccess } from "@/helpers";
import PaymentModal from "@/components/modals/PaymentModal.vue";
import { Dialog } from "quasar";

export default {
  data() {
    return {

    };
  },
  computed: {
    ...mapState([
      'currentUser',
      'userProfile',
    ]),
    ...mapGetters([
      'activePendingServers',
    ]),
  },
  props: {

  },
  mounted() {

  },
  unmounted() {
  },
  methods: {
    openPaymentModal() {
      this.$q.dialog({
        component: PaymentModal,
        componentProps: { }
      }).onOk(() => {
        console.log('OK');
      }).onCancel(() => {
        console.log('Cancel');
      }).onDismiss(() => {
        console.log('Dismiss: OK/Cancel');
      });
    },
    async toggleServer() {
      try {
        if (!this.userProfile.minutes || this.userProfile.minutes === 0) {
          Dialog.create({
            title: 'Insufficient Minutes',
            message: `You have insufficient minutes to start a session. Would you like to purchase more minutes?`,
            html: true,
            fullWidth: false,
            cancel: true,
            persistent: true,
          }).onOk(() => {
            this.openPaymentModal();
          }).onCancel(() => {
          });
        } else if (this.activePendingServers.length > 0) {
          Dialog.create({
            title: 'Close Session',
            message: `Are you sure you want to close your session?`,
            html: true,
            fullWidth: false,
            cancel: true,
            persistent: true,
          }).onOk(async () => {
            let response = await this.$store.dispatch('deleteServers');
            SendSuccess('Session Has Stopped');
            // SendSuccess(response.message);
          }).onCancel(() => {
          });
        } else {
          let response = await this.$store.dispatch('createServer');
          SendSuccess('Session Is Starting Please Wait');
          // SendSuccess(response.message);
        }
      } catch (err) {
        SendError(err);
        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss">
#server-dropdown {
  .q-btn-dropdown__arrow-container {
    display: none;
  }
}
</style>
