<template>
  <section style="width:fit-content;">
    <!--  :style="`width:${size}px;`" -->
    <q-avatar class="cursor-pointer bg-accent"
              ref="avatar"
              @mouseover="mouseOver = true"
              @mouseleave="mouseOver = false"
              :style="`border-radius:100%;box-shadow: 0 0 ${mouseOver ? '16' : '8'}px 0 rgba(0, 0, 0, ${mouseOver ? '0.5' : '0.2'});`"
              :size="`${size}px`"
              v-show="!userAvatar"
              @click="editAvatar">
      <img v-if="userProfile.photoURL" :src="userProfile.photoURL" alt="Profile Picture">
      <div v-else>{{(userProfile.name || '🌙').slice(0, 2).toUpperCase()}}</div>
      <div v-if="mouseOver && editable" class="absolute-center picture-overlay">
        Change Picture
      </div>
    </q-avatar>

    <!-- EDITING -->

    <vue-avatar
        ref="vueavatar"
        v-show="userAvatar"
        :width=200
        :height=200
        :rotation="rotation"
        :scale="scale"
        :borderRadius=200
        @vue-avatar-editor:image-ready="onImageReady"
    >
    </vue-avatar>

    <div v-show="userAvatar">
      <q-item>
        <q-item-section>
          <q-badge class="flex-center" color="secondary">
            Scale
          </q-badge>
          <q-slider
              outlined
              v-model="scale"
              :min=1
              :max=5
              :step=0.1
          ></q-slider>
        </q-item-section>
        <q-item-section>
          <q-badge class="flex-center" color="secondary">
            Rotation
          </q-badge>
          <q-slider
              outlined
              v-model="rotation"
              :min=0
              :max=360
              :step=0.1
          ></q-slider>
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section>
          <q-btn
              class="q-mt-md full-width"
              color="dark" text-color="white" unelevated rounded size="md"
              type="button"
              @click="handleUpload"
          >Upload</q-btn>
        </q-item-section>
        <q-item-section>
          <q-btn
              class="q-mt-md full-width"
              color="dark" text-color="white" unelevated rounded size="md"
              type="button"
              @click="userAvatar = null;$refs.vueavatar.resetImage()"
          >Cancel</q-btn>
        </q-item-section>
      </q-item>
    </div>

  </section>
</template>

<script>
import { mapState } from 'vuex';
import { VueAvatar } from 'vue-avatar-editor-improved';

import { storage, ref, uploadBytes, getDownloadURL } from '@/firebaseConfig';
import { SendError, SendSuccess } from "@/helpers";

export default {
  components: {
    VueAvatar,
  },
  mounted() {
  },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      mouseOver: false,
      userAvatar: null,
      rotation: 0,
      scale: 1,
    };
  },
  computed: {
    ...mapState([
      'currentUser',
      'userProfile'
    ]),
  },
  methods: {
    onImageReady() {
      this.userAvatar = this.$refs.vueavatar.getImageScaled();
      this.scale = 1;
      this.rotation = 0;
    },
    editAvatar() {
      if (this.editable) {
        this.$refs.vueavatar.clicked();
      } else {
        this.$router.push('/profile');
      }
    },
    handleUpload() {
      this.$store.commit('isLoading', true);

      try {
        this.$refs.vueavatar.getImageScaled().toBlob(async (blob)=> {

          const metadata = {
            contentType: 'image/jpeg',
          };

          let imageRef = ref(storage, `/images/${this.currentUser.uid}/picture.jpeg`);

          await uploadBytes(imageRef, blob, metadata);

          let photoURL = await getDownloadURL(imageRef);

          await this.$store.dispatch('updateUserProfile', {
            photoURL
          });

          this.userAvatar = null;
          this.$store.commit('isLoading', false);
          SendSuccess('Successfully Uploaded Profile Picture');
        });
      } catch(err) {
        console.error(err);
        SendError(err);
      }
    },
  },
  watch: {
    userAvatar() {
      this.$emit('update:editing', !!this.userAvatar);
    }
  },
};
</script>

<style scoped lang="scss">
.picture-overlay {
  text-align: center;
  font-size:18px;
  font-weight:bold;
  border-radius: 200px;
  height: 200px;
  width: 200px;
  background: #2c2323AA;
  color: $light;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>