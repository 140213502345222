<template>
  <section class="q-custom-content">

    <q-btn class="absolute-top-right q-ma-sm" :to="'/profile'" >Close</q-btn>

    <div class="row">

      <div class="offset-xl-1 offset-lg-1 offset-md-1 offset-sm-0 offset-xs-0 c col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <q-card class="panel-auth-parent text-center bg-transparent sm-hide xs-hide">
          <div class="panel-auth-child bg-glass-dark q-pa-lg">
            <div class="q-my-lg" v-if="tab === 'profile'">
              <h4>Create Your Profile</h4>
              <p>Creating your profile...</p>
            </div>
            <div class="q-my-lg" v-if="tab === 'audio'">
              <h4>Upload Your Voice</h4>
              <p>Creating your profile...</p>
            </div>
          </div>
        </q-card>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">

        <div class="panel-auth-parent text-center">
          <q-card class="panel-auth-parent text-center bg-transparent">
            <div class="panel-auth-child bg-glass q-pa-lg">
              <q-tabs
                  v-model="tab"
              >
                <q-tab name="profile" label="Profile"/>
                <q-tab name="audio" label="Audio" />
                <q-tab name="payment" label="Payment" />
              </q-tabs>

              <!-- PROFILE -->
              <div class="q-my-lg" v-if="tab === 'profile'">
                <p>Tell us a bit about yourself.</p>
                <ProfilePicture class="q-mt-lg q-mb-lg q-ml-auto q-mr-auto" :size="200"></ProfilePicture>

                <q-form @submit="updateUser" class="q-gutter-y-md q-mt-xl">
                  <q-input
                      class="q-mb-sm"
                      outlined
                      label="Name"
                      required
                      type="text"
                      v-model.trim="name" />
                  <q-input
                      class="q-mb-sm"
                      outlined
                      label="Description"
                      required
                      type="textarea"
                      v-model.trim="description" />
                  <q-btn
                      unelevated
                      class="full-width"
                      rounded
                      color="accent"
                      text-color="white"
                      type="submit"
                      :disabled="isLoading">
                    Save
                  </q-btn>
                </q-form>

              </div>

              <!-- AUDIO -->
              <div class="q-my-lg" v-if="tab === 'audio'">
                <p>Record your voice.</p>
                <Player v-if="audioURL" :audioURL="audioURL"/>

                <q-separator class="q-my-md" spaced v-if="audioURL" />

                <span class="text-accent">{{userProfile.transcript}}</span>

                <q-separator class="q-my-md" spaced v-if="userProfile.transcript" />

                <q-btn
                    unelevated
                    class="full-width"
                    rounded
                    color="accent"
                    text-color="white"
                    :disabled="recordStatus !== 'NONE'"
                    @click="openSpeechRecordModal"
                >
                  {{userProfile.transcript ? 'Relearn' : 'Learn'}} My Voice
                </q-btn>
              </div>


            </div>
          </q-card>
        </div>

      </div>

    </div>


    <!--
    <q-card class="rounded-borders q-ml-sm q-mr-sm q-mb-sm bg-glass">
      <q-card-section class="q-py-none">
        <q-item class="q-pa-none">
          <q-item-section class="q-pa-none">
            <h5 class="q-my-none">Profile</h5>
          </q-item-section>
        </q-item>
      </q-card-section>

      <q-separator />

      <q-form @submit="updateUser" class="q-gutter-y-md">
        <q-card-section>
          <div class="row q-col-gutter-sm">
            <div class="col-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <ProfilePicture class="q-ml-auto q-mr-auto q-mt-lg q-mb-lg"></ProfilePicture>
            </div>
            <div class="col-8 col-lg-8 col-md-8 col-sm-6 col-xs-12">
              <h5 class="q-my-sm">Details</h5>
              <q-input
                  class="q-mb-sm"
                  outlined
                  label="Email"
                  required
                  type="email"
                  v-model.trim="email"
              />
              <q-input
                  class="q-mb-sm"
                  outlined
                  label="Name"
                  required
                  type="text"
                  v-model.trim="name" />
            </div>
          </div>

          <q-btn
              unelevated
              class="full-width"
              rounded
              color="accent"
              text-color="white"
              type="submit"
              :disabled="isLoading">
            Save
          </q-btn>
        </q-card-section>
      </q-form>
    </q-card>

    <q-card class="rounded-borders q-ml-sm q-mr-sm q-mb-sm bg-glass">
      <q-card-section>
        <h6 class="q-my-sm">My Voice</h6>

        <Player v-if="audioURL" :audioURL="audioURL"/>

        <q-separator class="q-my-md" spaced v-if="audioURL" />

        <span class="text-accent">{{userProfile.transcript}}</span>

        <q-separator class="q-my-md" spaced v-if="userProfile.transcript" />

        <q-btn
            unelevated
            class="full-width"
            rounded
            color="accent"
            text-color="white"
            :disabled="recordStatus !== 'NONE'"
            @click="openSpeechRecordModal"
        >
          {{userProfile.transcript ? 'Relearn' : 'Learn'}} My Voice
        </q-btn>
      </q-card-section>
    </q-card>
    <q-card class="rounded-borders q-ma-sm bg-transparent">
      <q-btn
          rounded
          class="full-width"
          :disabled="isLoading"
          @click="logout"
      >
        Logout
      </q-btn>
    </q-card>
    -->
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { SendError, SendSuccess } from "@/helpers.js";

import Player from '@/components/blocks/Player.vue';
import SpeechRecordModal from '@/components/modals/SpeechRecordModal.vue';
import ProfilePicture from '@/components/blocks/ProfilePicture.vue';

export default {
  components: {
    Player,
    ProfilePicture,
  },
  async beforeMount() {
    await this.setDefaults();
  },
  async mounted() {
  },
  async unmounted() {
  },
  data() {
    return {
      URL,
      tab: 'profile',
      name: '',
      email: '',
      description: '',
      audioURL: '',
    };
  },
  computed: {
    ...mapState([
      'isLoading',
      'userProfile',
      'currentUser',
      'recordStatus',
      'servers',
    ]),
    ...mapGetters([
      'activeServers',
    ]),
  },
  methods: {
    async setDefaults() {
      if (this.userProfile.name) {
        this.name = this.userProfile.name;
      }
      if (this.userProfile.description) {
        this.description = this.userProfile.description;
      }
      if (this.userProfile.audioURL) {
        this.audioURL = this.userProfile.audioURL;
      }
      this.$store.commit('isLoading', false);
    },
    async updateUser() {
      try {
        this.$store.commit('isLoading', true);
        await this.$store.dispatch('updateUserProfile', {
          name: this.name,
          description: this.description,
        });
        SendSuccess(`Successfully Updated Profile`);
        this.tab = 'audio';
        setTimeout(() => this.setDefaults(), 100);
      } catch (err) {
        SendError(err);
      }
    },
    async logout() {
      this.$router.push('/logout');
    },
    openSpeechRecordModal() {
      this.$q.dialog({
        component: SpeechRecordModal,
      }).onOk(() => {
        console.log('OK');
        this.audioURL = '';
        setTimeout(() => this.setDefaults(), 100);
      }).onCancel(() => {
        console.log('Cancel');
      }).onDismiss(() => {
        console.log('Dismiss: OK/Cancel');
      });
    },
  },
  watch: {
    userProfile: {
      handler(newItem, oldItem) {
        this.setDefaults();
      },
      deep: true,
    },
  }
};
</script>

<style scoped lang="scss">

.panel-auth-parent {
  display: flex;
  flex-direction: column;
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}
.panel-auth-child {
  max-width: 600px;
  width: 80%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 599.99px) {
    width: 95%;
    top: 100px;
    transform: translate(-50%, 0);
  }
}
</style>