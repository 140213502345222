<template>
  <div class="flex flex-center">
    <q-btn
      class="q-ma-sm"
      v-if="!recordIsAllowed"
      @click="requestRecording"
      :class="$q.dark.isActive ? 'bg-dark text-white' : 'bg-white text-dark'"
    >
      <q-icon name="mdi-microphone-question" size="sm"></q-icon>
    </q-btn>
    <q-btn
      rounded
      class="q-ma-sm bg-accent text-white"
      bg-color="white"
      v-if="recordIsAllowed && recordStatus === 'NONE'"
      @click="toggleRecording(false)"
    >
      <q-icon name="img:/images/mic-fill.svg" size="xs"> </q-icon>
      <div class="on-right">Start Recording</div>
    </q-btn>
    <q-card
      v-if="recordStatus !== 'NONE'"
      rounded
      class="bg-dark flex flex-center q-px-md"
    >
      <div class="text-white text-weight-bold text-subtitle1 on-left">
        {{ FormatPlayerTime(recordDuration) }}
      </div>
      <q-btn
        round
        size="md"
        class="q-ma-sm bg-dark text-white"
        @click="togglePauseRecording"
      >
        <q-icon
          :name="
            recordStatus === 'PAUSED'
              ? 'mdi-play'
              : 'mdi-pause'
          "
          size="md"
        ></q-icon>
      </q-btn>
      <q-btn
        round
        size="md"
        class="q-ma-sm bg-dark text-white"
        @click="toggleRecording(true)"
      >
        <q-icon name="mdi-stop" size="md"> </q-icon>
      </q-btn>
    </q-card>
  </div>
</template>

<script setup>
import { SendError, SendSuccess, FormatPlayerTime } from "@/helpers.js";

import { VoiceRecorder } from 'capacitor-voice-recorder';
import { ref, onMounted } from 'vue';

const recordIsAllowed = ref(false);

onMounted(() => {
  requestRecording();
});

const requestRecording = async () => {
  if (recordIsAllowed.value) {
    return true;
  }

  const canRecord = await VoiceRecorder.canDeviceVoiceRecord();
  if (!canRecord.value) {
    SendError('Device does not support audio recording. Please check your app permissions');
    return false;
  } else {
    const result = await VoiceRecorder.requestAudioRecordingPermission();
    if (result.value) {
      recordIsAllowed.value = true;
      return true;
    } else {
      SendError('Cannot access microphone. Please check your app settings');
      return false;
    }
  }
};

defineProps({
  recordStatus: {
    type: String,
    required: true,
  },
  recordDuration: {
    type: Number,
    required: true,
  },
  togglePauseRecording: {
    type: Function,
    required: true,
  },
  toggleRecording: {
    type: Function,
    required: true,
  },
});
</script>
