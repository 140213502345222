// helpers.js

import { Notify } from 'quasar';
import { Capacitor } from '@capacitor/core';
import moment from 'moment/moment';

import store from '@/store/store';

import { FirebaseAuthentication, getIdToken } from "@/firebaseConfig.js";

const ApiRequest = async (options) => {
  try {
    // body, params, headers, method, url

    const baseURL = import.meta.env.VITE_API_URL;

    let token = null;
    try {
      if (store.state.currentUser) {
        if (Capacitor.isNativePlatform()) {
          token = (await FirebaseAuthentication.getIdToken()).token;
        } else {
          token = await getIdToken(store.state.currentUser);
        }
      }
    } catch (err) {
      console.error(err);
    }

    // Create a new headers object with the Authorization header
    const headers = new Headers(options.headers || {});
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    // Set content type
    if (typeof options.body === 'object' && options.body instanceof FormData) {
      headers.delete('Content-Type');
    } else if (options.body) {
      headers.set('Content-Type', 'application/json');
      options.body = JSON.stringify(options.body);
    }

    // Construct the URL with query parameters
    const urlWithParams = new URL(`${baseURL}${options.url}`);
    if (options.params) {
      Object.entries(options.params).forEach(([key, value]) => {
        urlWithParams.searchParams.append(key, value);
      });
    }

    const response = await fetch(urlWithParams, {
      ...options,
      headers: headers,
    });

    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  } catch(err) {
    throw err;
  }
};

// Generic Success Handler
const SendSuccess = (message) => {
  store.commit('isLoading', false);

  if (message) {
    Notify.create({
      message: message || 'Success',
      position: 'top',
      color: 'positive',
      // closeBtn: true,
      // queue: true,
      actions: [
        { icon: 'mdi-close', color: 'white', size: 'sm' },
        // { label: '', color: 'white', handler: () => {  } },
      ]
    });
  }
};

// Generic Error Handler
const SendError = (err) => {
  store.commit('isLoading', false);

  // Console Log Error
  console.error(err);

  // Default Error
  let message = 'Oops, something went wrong! Please try again later.';

  if (typeof err === 'string') {
    message = err;
  }

  if (typeof err === 'object') {
    if (typeof err.response === 'object'
      && typeof err.response === 'object'
      && typeof err.response.message === 'string'
      && err.response.message !== '') {
      message = err.response.message;
    } else if (typeof err.message === 'string'
      && err.message !== '') {
      message = err.message;
    }
  }

  Notify.create({
    message: message || 'Error',
    position: 'top',
    color: 'negative',
    // closeBtn: true,
    actions: [
      { icon: 'mdi-close', color: 'white', size: 'sm' },
      // { label: '', color: 'white', handler: () => {  } },
    ]
  });
};

const GetVersion = () => {
  return `${import.meta.env.VITE_VERSION || '0.0.0'}`;
};

const GetRelease = () => {
  let platform = (window.matchMedia("only screen and (max-width: 768px)").matches) ? 'web_mobile' : 'web_desktop';

  if (Capacitor.isNativePlatform() && window.device?.platform) {
    // ios, android
    platform =  window.device.platform;
  }

  return `${platform}-${GetVersion()}`;
};

const RouteToLink = (link) => {
  // This function takes a link and converts it to a dynamic link, and routes accordingly

  // Get the user agent of the browser
  const userAgent = navigator?.userAgent || '';

  // TikTok
  // Mozilla/5.0 (Linux; Android 13; Pixel 6 Build/TQ3A.230805.001.A2; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/117.0.0.0 Mobile Safari/537.36 musical_ly_2023101150 JsSdk/1.0 NetType/WIFI Channel/beta AppName/musical_ly app_version/31.1.15 ByteLocale/en ByteFullLocale/en Region/US Spark/1.4.0.5-bugfix AppVersion/31.1.15 BytedanceWebview/d8a21c6

  // Check the user agent and redirect accordingly
  if (/ByteLocale|Instagram/.test(userAgent)) {
    window.location.href = link;
  } else {
    window.location.href = `${import.meta.env.VITE_DYNAMIC_URL}/?link=${encodeURIComponent(link)}&apn=${import.meta.env.VITE_ANDROID_BUNDLE_ID}&ibi=${import.meta.env.VITE_APPLE_BUNDLE_ID}&isi=${import.meta.env.VITE_APPLE_APP_ID}`;
  }
};

const FormatPlayerTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secondsRemainder = Math.round(seconds) % 60;
  const paddedSeconds = `0${secondsRemainder}`.slice(-2);
  return `${minutes}:${paddedSeconds}`;
};

const FormatDate = (firebaseTimestamp, format='DD MMM YYYY - HH:mm') => {
  return moment
    .unix(firebaseTimestamp._seconds || firebaseTimestamp.seconds)
    .format(format);
};

function DataURLtoFile(dataURL, filename) {
  // Split the data URL at the comma to get the base64 encoded data
  const arr = dataURL.split(',');
  // Decode the base64 data
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  // Create a blob with the mime type and the decoded data
  const blob = new Blob([u8arr], { type: mime });
  // Return a file object
  const fileType = mime.split('/')[1];
  return new File([blob], `${filename}.${fileType}`, { type: mime });
}

export {
  ApiRequest,
  SendSuccess,
  SendError,
  GetVersion,
  GetRelease,
  RouteToLink,
  FormatPlayerTime,
  FormatDate,
  DataURLtoFile,
};
