// router.js

import { createRouter, createWebHistory, START_LOCATION } from 'vue-router';
import { Capacitor } from '@capacitor/core';
import { Dialog } from 'quasar';

import store from '@/store/store';

import { readData } from '@/database';

import { RouteToLink } from '@/helpers';

import Main from '@/components/pages/Main.vue';
import Onboarding from '@/components/pages/Onboarding.vue';
import Logout from '@/components/pages/Logout.vue';
import Auth from '@/components/pages/Auth.vue';
import Settings from '@/components/pages/Settings.vue';
import Terms from '@/components/pages/Terms.vue';
import Privacy from '@/components/pages/Privacy.vue';
import Empty from '@/components/pages/404.vue';

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const el = window.location.href.split("#")[1];
      if (el.length) {
        document.getElementById(el).scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
          block: 'start'
        });
      }, 300);
    }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Main,
      meta: {
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: Auth,
      meta: {
        hideFooter: true,
        darkNav: true,
      },
    },
    {
      path: '/signup',
      name: 'SignUp',
      component: Auth,
      meta: {
        hideFooter: true,
        darkNav: true,
      },
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: Auth,
      meta: {
        hideFooter: true,
        darkNav: true,
      },
    },
    {
      path: '/onboarding',
      name: 'Onboarding',
      component: Onboarding,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/s',
      name: 'Chat',
      component: Main,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/s/:sessionId',
      name: 'ChatSession',
      component: Main,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/stream',
      name: 'Stream',
      component: Main,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Main,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout,
      meta: {
      },
    },
    {
      path: '/settings/:option',
      name: 'Settings',
      component: Settings,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/terms',
      name: 'Terms',
      component: Terms,
      meta: {
      },
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy,
      meta: {
      },
    },
    {
      path: '/404',
      name: '404',
      component: Empty,
      meta: {
      },
    },
  ]
});

let startTime = new Date();

// This callback runs before every route change, including on page load.
router.beforeEach(async (to, from, next) => {
  // Initial Navigation
  if (from === START_LOCATION) {
    store.commit('setQuery', to.query);

    if (to.query.session_id) {
      setTimeout(() => {
        Dialog.create({
          title: 'Thank you for your purchase',
          message: `Your minutes will be available momentarily on your account.`,
          html: true,
          fullWidth: false,
        }).onOk(async () => {
        }).onCancel(() => {
        });
      }, 3000);
    }

    /*
    // Retrieve stored database of localRecordings
    const localRecordings = await readData('localRecordings');
    if (localRecordings) {
      store.commit('setLocalRecordings', localRecordings);
    }
     */
  }

  // Auto refresh if greater than 12 hours hold
  if (new Date().getTime() - startTime.getTime() > (60 * 60 * 12 * 1000)) {
    window.location.reload();
  }

  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  let currentUser = store.state.currentUser;
  let userProfile = store.state.userProfile;

  if (currentUser && !userProfile?.id) {
    // If currentUser exists, let's wait until userProfile exists. T
    // This is done in firebaseListeners
    // This function waits a maximum of 100ms * 100ms = 10 seconds
    const waitForUserProfile = async () => {
      let intervalCount = 0;
      return await new Promise(resolve => setInterval(() => {
        if (store.state.userProfile.id || intervalCount === 100) {
          resolve();
          clearInterval(this);
        }
        intervalCount++;
      }, 100));
    };
    await waitForUserProfile();
  }

  // Hard refresh, auto logged in
  if (requiresAuth && currentUser && (!userProfile || !userProfile.email)) {
    currentUser = store.state.currentUser;
    userProfile = store.state.userProfile;
    await store.dispatch('getUserProfile', {
      userId: store.state.currentUser.uid,
    });
  }

  if (requiresAuth && !currentUser) {
    store.commit('setQuery', {
      ...to.query,
      redirect: encodeURI(to.path),
    });
    next(`/login`);
  } else if (to.meta.requiresAdmin && !userProfile.admin) {
    next('/404');
  } else if (to.matched.length === 0) {
    next('/404');
  } else {
    next();
  }

  store.commit('appLoaded', true);
});

router.afterEach((to) => {
});

export default router;
