<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="modal-container">
      <q-card-section>
        <q-card class="text-center flex column full-height">
          <q-card-section class="flex">
            <q-btn v-close-popup >Close</q-btn>
          </q-card-section>
          <q-separator class="q-mx-md" spaced />
          <q-card-section>

            <Player v-if="!isLoading && audioURL" :audioURL="audioURL" />
            <q-linear-progress
              color="accent"
              v-else
              rounded
              :value="progress / 100"
            ></q-linear-progress>

          </q-card-section>

          <q-separator class="q-mx-md" spaced />
          <q-card-actions vertical>
            <q-btn :disabled="isLoading" @click="uploadSpeechSample">Save</q-btn>
          </q-card-actions>
        </q-card>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent } from 'quasar';

import { mapState, mapGetters } from 'vuex';
import {  SendError, SendSuccess, DataURLtoFile, } from '@/helpers.js';

import Player from '@/components/blocks/Player.vue';

// import { useLocalNotification } from '@/hooks/useLocalNotification';

export default {
  components: {
    Player,
  },
  data() {
    return {
      progress: 0,
    };
  },
  props: {
    audioURL: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
  },
  emits: [
    ...useDialogPluginComponent.emits,
  ],
  computed: {
    ...mapState([
      'isLoading',
      'recordingWidget',
      'recordStatus',
      'servers',
    ]),
    ...mapGetters([
      'activeServers',
    ]),
  },
  async mounted() {

  },
  async unmounted() {

  },
  methods: {
    onDialogHide() {
      this.$emit('hide');
    },
    onOKClick() {
      this.$emit('ok', {});
      this.onDialogHide();
    },
    onCancelClick() {
      this.$emit('hide');
    },
    //
    async uploadSpeechSample() {
      try {
        this.$store.commit('isLoading', true);

        let file = DataURLtoFile(this.audioURL, 'audio');
        let formData = new FormData();
        formData.append('file', file);

        let response = await this.$store.dispatch('uploadUserAudio', formData);
        SendSuccess('Successfully Uploaded Speech');
        this.$store.commit('isLoading', false);
        this.onOKClick();
      } catch (err) {
        SendError(err);
      }
    },
  },
  watch: {
  },
};
</script>

<style scoped lang="scss">
.modal-container {
  min-width: 50%;
  @media screen and (max-width: 599.99px) {
    min-width: 100%;
  }
}
</style>