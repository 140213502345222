<template>
  <section class="q-custom-content">

    <!-- EMPTY -->
    <q-card class="panel-parent rounded-borders q-ml-sm q-mr-sm q-mb-sm bg-glass"
            v-show="!session?.sessionId">
    </q-card>

    <!-- CHAT -->
    <q-card class="panel-parent rounded-borders q-ml-sm q-mr-sm q-mb-sm bg-glass"
            v-show="session?.sessionId && (!isSettingSocket && socket?.connected && activeServers.length > 0)">

      <!-- TOP -->
      <q-card-section class="q-py-none">
        <q-item style="padding:0;">
          <q-item-section class="text-h5" style="padding:0;">
            <h5 class="q-my-none"><span class="text-secondary text-subtitle2">{{duration}}</span></h5>
          </q-item-section>
          <q-item-section side style="padding:0;">
            <q-checkbox size='md' v-model="isShowingMessages" checked-icon="mdi-video-account" unchecked-icon="mdi-comment-text-multiple-outline" />
          </q-item-section>
          <q-item-section side style="padding:0;">
            <q-checkbox size='md' v-model="isAudio" checked-icon="mdi-volume-high" unchecked-icon="mdi-volume-off" />
          </q-item-section>
        </q-item>
      </q-card-section>

      <!-- CENTER -->
      <q-scroll-area
          ref="scrollArea"
          class="panel-center-child relative-position q-ma-sm">

        <!-- VIDEO -->
        <div class="absolute-center text-center video-head" v-show="!isShowingMessages">
          <audio ref="audioQueue" controls style="display:none">
          </audio>
          <q-item class="q-pa-none q-ma-none">
            <q-item-section side style="width:60px;">
              <q-btn icon="mdi-arrow-left-thin" size="sm"
                     style="width:40px;height:40px;"
                     v-show="videoPlayerQueue?.canPlayPrevious()"
                     @click="videoPlayerQueue.playPrevious();resetLastSpoke();"></q-btn>
            </q-item-section>
            <q-item-section>
              <div class="video-container">
                <video ref="videoQueue"
                       class="video-overlay"
                       v-show="videoPlayerQueue?.isPlaying"
                       :controls="false" autoplay>
                </video>
                <transition-group name="slow-fade">
                  <div v-if="session?.videoURL">
                    <video :controls="false" autoplay muted loop class="video-background">
                      <source :src="session.videoURL" type="video/mp4">
                    </video>
                  </div>
                  <div class="relative-position video-background" style="opacity:0.5;"
                       v-else>
                    <q-spinner-dots size="lg" class="absolute-center"></q-spinner-dots>
                  </div>
                </transition-group>
              </div>
            </q-item-section>
            <q-item-section side style="width:60px;">
              <q-btn icon="mdi-arrow-right-thin" size="sm"
                     style="width:40px;height:40px;"
                     v-show="videoPlayerQueue?.canPlayNext()"
                     @click="videoPlayerQueue.playNext();resetLastSpoke();"></q-btn>
            </q-item-section>
          </q-item>
        </div>

        <div class="absolute-bottom text-center q-mx-lg">
          <p class="text-white" v-if="videoPlayerQueue?.text">
            {{videoPlayerQueue.text}}
          </p>

          <p class="text-secondary" v-if="currentUserMessage?.text">
            {{currentUserMessage.text}}
          </p>
        </div>

        <!-- MESSAGES -->
        <div class="q-pa-md" v-if="isShowingMessages">
          <transition-group
                            name="list"
                            tag="div"
          >
            <!-- CONVERSATION -->
            <div v-if="sessionMessages.length > 0">
              <q-chat-message
                  class="fade-from-bottom"
                  v-for="(message, index) in sessionMessages" :key="index"
                  :text="[message.text]"
                  :sent="message.type === 'user'"
                  :text-color="message.type === 'user' ? 'accent' : 'white'"
                  :bg-color="message.type === 'user' ? 'secondary' : 'primary'"
                  :class="message.type === 'user' ? 'text-right' : 'text-left'"
              >
                <template v-slot:name>
                  <q-item class="q-pa-none" style="min-height: 0;">
                    <q-item-section side class="q-pa-xs">
                      {{message.type === 'user' ? (userProfile.name || 'User') : 'Bot'}}
                    </q-item-section>
                    <q-item-section side>
                      <q-icon size='xs' class='text-accent cursor-pointer'
                              :name="message.showAudio ? 'mdi-pause-circle-outline' : 'mdi-play-circle-outline'"
                              @click="message.showAudio = !message.showAudio"
                              v-if="message.audioURL"></q-icon>
                    </q-item-section>
                    <q-item-section class="q-ma-sm" v-if="message.showAudio">
                      <Player :audioURL="message.audioURL" :mini="true" :autoplay="true"/>
                    </q-item-section>
                  </q-item>
                </template>
                <template v-slot:avatar>
                  <!--<img
                      class="q-message-avatar"
                      :class="message.type === 'user' ? 'q-message-avatar--received' : 'q-message-avatar--sent'"
                      :src="message.type === 'user' ? (userProfile.photoURL || 'https://cdn.quasar.dev/img/avatar4.jpg') : 'https://cdn.quasar.dev/img/avatar2.jpg'"
                  >-->
                </template>
                <template v-slot:stamp >
                  {{ moment.unix(message.createdAt.seconds).fromNow() }}
                </template>
              </q-chat-message>
            </div>
            <div class="absolute-center" v-else>
              No Messages
            </div>

            <!-- CHAT BUBBLES USER -->
            <q-chat-message
                v-if="isUserSpeaking"
                class="fade-from-bottom text-right"
                :sent="true"
                text-color="accent"
                bg-color="secondary"
            >
              <template v-slot:name>
                {{(userProfile.name || 'User')}}
              </template>
              <template v-slot:avatar>
                <!--<img
                    class="q-message-avatar q-message-avatar--received"
                    :src="(userProfile.photoURL || '/images/default.png')">-->
              </template>
              <template v-slot:stamp >
              </template>
              <q-spinner-dots size="lg"></q-spinner-dots>
            </q-chat-message>

            <!-- CHAT BUBBLES BOT -->
            <q-chat-message
                v-if="isBotSpeaking"
                class="fade-from-bottom text-left"
                :sent="false"
                text-color="white"
                bg-color="primary"
            >
              <template v-slot:name>
              </template>
              <template v-slot:avatar>
              </template>
              <template v-slot:stamp >
              </template>
              <q-spinner-dots size="lg"></q-spinner-dots>
            </q-chat-message>

          </transition-group>
        </div>

      </q-scroll-area>

      <!-- BOTTOM -->
      <q-card-section class="q-pt-none">
        <q-item :style="isMobile ? 'display-block' : ''" style="padding:0;">
          <q-item-section>
            <q-input v-model="message" @keyup.enter="sendMessage" placeholder="Type a message..." outlined>
              <template v-slot:after>
                <div class="rounded-borders q-gutter-md">
                  <q-icon class="cursor-pointer"
                          name="mdi-send" :color="message ? 'blue' : 'primary'"
                          v-if="message"
                          size="sm"
                          @click="sendMessage"></q-icon>
                  <q-icon class="cursor-pointer"
                          :name="microphoneOpen ? 'mdi-microphone' : (microphoneError ? 'mdi-microphone-question' : 'mdi-microphone-off')"
                          :color="microphoneOpen ? 'green' : (microphoneError ? 'red' : 'secondary')"
                          size="sm"
                          @click="toggleMicrophoneVAD"></q-icon>
                </div>
              </template>
            </q-input>
          </q-item-section>
        </q-item>
      </q-card-section>

    </q-card>

    <!-- SETUP -->
    <q-card class="panel-parent rounded-borders q-ml-sm q-mr-sm q-mb-sm bg-glass"
            v-show="session?.sessionId && (isSettingSocket || !socket?.connected || activeServers.length === 0)">
      <div class="absolute-center text-center">
        <q-btn class="rounded-borders q-mt-md" color="secondary" text-color="white"
               :disabled="isSettingSocket || activeServers.length === 0"
               @click="setupSocket">
          {{ isSettingSocket ? 'Starting...' : `Continue Session` }}
        </q-btn>
      </div>

    </q-card>

  </section>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters, } from "vuex";
import io from 'socket.io-client';
import { MicVAD, utils } from '@ricky0123/vad-web';

import AudioPlayerQueue from '@/classes/AudioPlayerQueue.js';
import VideoPlayerQueue from '@/classes/VideoPlayerQueue.js';
import Player from '@/components/blocks/Player.vue';

import { SendError, SendSuccess } from '@/helpers.js';
import { FirebaseAuthentication } from '@/firebaseConfig';
import { createMessageListener } from "@/firebaseListeners";

export default {
  components: {
    Player,
  },
  beforeMount() {
  },
  async mounted() {
    if (this.$route.params.sessionId) {
      console.log('Session ID:', this.$route.params.sessionId);

      // Required for messages computed property
      this.sessionId = this.$route.params.sessionId;

      if (!this.session) {
        console.log('Session not found');
        return this.$router.push('/s');
      }

      // Create Message Listener
      await createMessageListener(this.sessionId);

      // Setup Socket
      await this.setupSocket();
    } else {
      // No Session
      this.$store.commit('setDrawer', true);
    }
  },
  async unmounted() {
    await this.disconnectSocket();
  },
  data() {
    return {
      URL,
      moment,
      sessionId: null,
      isLoading: false,
      isAudio: true,
      isShowingMessages: false,
      audioURL: null,
      messageQueue: [],
      currentMessage: null,
      currentUserMessage: null,
      isSettingSocket: false,
      isUserSpeaking: false,
      isBotSpeaking: false,
      duration: null,
      audioPlayerQueue: null,
      videoPlayerQueue: null,
      message: '',
      socket: null,
      microphoneOpen: false,
      microphoneError: false,
      microphone: null,
      audioChunks: [],
      isMonitoringVolume: false,
      quietInterval: null,
      lastSpoke: moment().format(),
      lastSpokeCount: 0,
    };
  },
  computed: {
    ...mapState([
      'userProfile',
      'currentUser',
      'sessions',
      'messages',
      'servers',
    ]),
    ...mapGetters([
      'activeServers',
    ]),
    isMobile() {
      return (window.innerWidth < 1024);
    },
    sessionMessages() {
      return this.messages[this.sessionId] || [];
    },
    session() {
      if (this.sessions.length > 0) {
        return this.sessions.find(s => s.sessionId === this.$route.params.sessionId) || null;
      }
      return null;
    },
  },
  methods: {
    async setupSocket() {
      if (!this.servers[0]?.serverId) {
        return SendError('No server available');
      }

      if (!this.userProfile.llmModel ||
          !this.userProfile.sttModel ||
          !this.userProfile.ttsModel ) {
        return this.$q.dialog({
          title: 'Missing Required Information',
          message: 'Please complete your profile before continuing.',
          cancel: true,
          html: true,
          ok: {
            color: 'accent',
            label: 'Okay',
          }
        }).onOk(async () => {
          this.$router.push('/profile');
        }).onCancel(() => {
        });
      }

      this.isSettingSocket = true;
      let intervalId;

      const websocketURL = `${import.meta.env.VITE_NODE_ENV === 'production' ? 'wss' : 'ws'}://${import.meta.env.VITE_API_URL.replace('http://', '').replace('https://', '')}`;
      console.log(`Setting up socket ${websocketURL}`);

      // Connect to the WebSocket server
      this.socket = io(`${websocketURL}`, {
        reconnectionDelayMax: 10000,
        transports : ['websocket'],
        auth: {
          token: (await FirebaseAuthentication.getIdToken()).token,
          sessionId: this.session.sessionId,
          serverId: this.servers[0].serverId,
        },
      });

      this.socket.on('connect', () => {
        console.log(`WebSocket Connected`);

        this.isSettingSocket = false;
        const startTime = moment().format();

        // Set up an interval to update the countdown every second
        intervalId = setInterval(() => {
          // Calculate the current moment and the duration until the end time
          let now = moment();
          let duration = moment.duration(now.diff(startTime));
          // Show minutes if > 0
          if (duration.minutes() > 0) {
            this.duration = `${duration.minutes()}m:${duration.seconds()}s`;
          } else {
            this.duration = `${duration.seconds()}s`;
          }
        }, 1000);

        // Setup interval to check if the user is speaking
        if (this.quietInterval) {
          clearInterval(this.quietInterval);
        }
        this.quietInterval = setInterval(() => {
          if (this.lastSpokeCount < 6 && moment() > moment(this.lastSpoke).add(50, 'seconds')) {
            console.log(`Sending empty message (${this.lastSpokeCount})`);
            this.lastSpoke = moment().format();
            this.lastSpokeCount = this.lastSpokeCount + 1;
            // Off For Now
            // this.socket.emit('messageData', '*system*');
          }
        }, 1000);

        this.$nextTick(() => {
          this.scrollToBottom();
        });
      });

      this.socket.on('disconnect', (reason) => {
        console.log(`WebSocket Disconnected: ${reason}`);
        this.isSettingSocket = false;

        if (intervalId) {
          clearInterval(intervalId);
        }
      });

      this.socket.on('message', (data) => {
        try {
          // Convert string data to a message object
          const messageObject = JSON.parse(data);
          console.log('Received message:', messageObject);

          // We don't send messages through the socket anymore
          /*
          if (messageObject.audioBuffer) {
            // Play the audio
            if (this.isAudio) {
              // this.audioPlayerQueue.enqueue(messageObject.audioBuffer); // base64Audio
            }
          } else if (messageObject.text) {
            // Append the message to the list
            // this.messages.push(messageObject);
            this.isBotSpeaking = false;
          }
          */

        } catch (err) {
          console.error('Error processing message:', err);
        }
      });

    },
    async disconnectSocket() {
      console.log('Disconnecting socket');

      try {
        this.isMonitoringVolume = false;
        if (this.microphone) {
          this.microphone.pause();
          this.microphone = null;
        }

        if (this.socket) {
          this.socket.disconnect();
        }
      } catch (err) {
        console.error('Error disconnecting:', err);
      }
    },
    /*
    async toggleMicrophone() {
      if (this.microphoneOpen) {
        // Stop the microphone
        console.log('Stopping microphone');

        this.microphoneOpen = false;
        this.microphone.stop();
      } else {
        // Start the microphone
        console.log('Starting microphone');
        this.microphoneOpen = true;

        const userMedia = await navigator.mediaDevices.getUserMedia({
          audio: {
            noiseSuppression: true,
            echoCancellation: true,
            channelCount: 1,
            sampleRate: 16000,
          }
        });

        this.microphone = new MediaRecorder(userMedia, { mimeType: 'audio/webm' });

        // Set up silence detection (using Web Audio API)
        const audioContext = new AudioContext();
        const analyserNode = audioContext.createAnalyser();
        const sourceNode = audioContext.createMediaStreamSource(userMedia);
        sourceNode.connect(analyserNode);
        analyserNode.fftSize = 512;
        const bufferLength = analyserNode.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        // Set thresholds for silence detection
        const silenceThreshold = 0.02; // Adjust this value based on testing
        let isSilent = true;

        // Listen for dataavailable event
        this.microphone.addEventListener('dataavailable', (event) => {
          this.audioChunks.push(event.data);
        });

        // Listen for pause event (silence detected)
        this.microphone.addEventListener('pause', async () => {
          console.log('Recording paused due to silence');

          // Send to socket
          if (this.audioChunks.length > 0) {
            console.log('Sending audio data to socket:', this.audioChunks.length);

            // Combine all audio chunks into a single Blob
            const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });

            // Convert the Blob to a File
            const audioFile = new File([audioBlob], 'audio.webm', { type: 'audio/webm' });

            // Send the File to the server
            this.socket.emit('audioData', audioFile);

            // Reset the audio chunks
            // this.audioChunks = [];

            // Keep the first chunk
            this.audioChunks = this.audioChunks.slice(0, 1);

            // Send the Buffer to the server
            // this.socket.emit('audioData', this.audioChunks);
          }
        });

        // Listen for resume event (speech detected)
        this.microphone.addEventListener('resume', () => {
          console.log('Recording resumed after speech detected');
        });

        // Start recording
        // The value here has to be long enough to cover the init buffer header
        this.microphone.start(100);

        // Monitor volume levels and pause/resume recording based on silence/speech detection
        let silenceTimer = null;
        const silenceDelay = 2000; // Delay before pausing the microphone when silence is detected (in milliseconds)

        function monitorVolume() {
          // Get the current volume data
          analyserNode.getByteTimeDomainData(dataArray);

          // Calculate the average volume of this sample
          let sum = 0;
          for(let i = 0; i < bufferLength; i++) {
            const x = dataArray[i] / 128.0 - 1.0; // Normalize the value
            sum += x * x; // Get the power of the signal
          }
          const averageVolume = Math.sqrt(sum / bufferLength);

          // Check if the current volume is below the threshold
          if (averageVolume < silenceThreshold) {
            if (!isSilent && !silenceTimer) {
              // Start a timer to pause the microphone after a delay
              silenceTimer = setTimeout(() => {
                this.microphone.pause();
                isSilent = true;
                silenceTimer = null;
              }, silenceDelay);
            }
          } else {
            if (isSilent) {
              this.microphone.resume();
              isSilent = false;
            }
            // If the volume is above the threshold, clear the timer to prevent the microphone from pausing
            if (silenceTimer) {
              clearTimeout(silenceTimer);
              silenceTimer = null;
            }
          }
          // Continue monitoring the volume
          if (this.isMonitoringVolume) {
            requestAnimationFrame(monitorVolume);
          }
        }

        monitorVolume = monitorVolume.bind(this);
        this.isMonitoringVolume = true;
        monitorVolume();
      }
    },
    */
    async toggleMicrophoneVAD() {
      if (this.microphoneOpen) {
        // Stop the microphone
        console.log('Stopping microphone...');

        this.microphoneOpen = false;
        this.microphone.pause();
      } else {
        // Start the microphone
        console.log('Starting microphone...');

        // Check permissions
        try {
          await navigator.mediaDevices.getUserMedia({
            audio: {
              noiseSuppression: true,
              echoCancellation: true,
              channelCount: 1,
              sampleRate: 16000,
            }
          });
        } catch(err) {
          this.microphoneError = true;
          if (err.name === 'NotAllowedError') {
            console.error('Permission denied by user:', err);
            SendError('Please allow microphone permissions');
          } else if (err.name === 'NotFoundError') {
            console.error('No microphone found:', err);
            SendError('No working microphone was found on your device.');
          } else if (err.name === 'NotReadableError') {
            console.error('Microphone not currently available:', err);
            SendError('No working microphone was found on your device.');
          } else {
            console.error('Error getting microphone:', err);
            SendError('Could not connect to your microphone. Please try again.');
          }
          return;
        }

        this.microphoneError = false;
        this.microphoneOpen = true;

        // Start MicVAD
        this.microphone = await MicVAD.new({
          // positiveSpeechThreshold: 0.8 - 0.15,
          // negativeSpeechThreshold: 0.8,
          // minSpeechFrames: 5,
          // preSpeechPadFrames: 1,
          workletURL: `${import.meta.env.VITE_APP_URL}/vad.worklet.bundle.min.js`,
          modelURL: `${import.meta.env.VITE_APP_URL}/silero_vad.onnx`,
          ortConfig: (ort) => {
            ort.env.wasm.wasmPaths = `${import.meta.env.VITE_APP_URL}/`;
          },
          redemptionFrames: 20,
          onSpeechEnd: (audio) => {
            const wavBuffer = utils.encodeWAV(audio);
            const base64 = utils.arrayBufferToBase64(wavBuffer);
            this.socket.emit('audioData', base64);
            this.resetLastSpoke();
            if (!this.audioPlayerQueue.isPlaying) {
              this.audioPlayerQueue.playNext();
            }
            this.isUserSpeaking = false;

            /*
            setTimeout(() => {
              this.isBotSpeaking = true;
              this.scrollToBottom();
            }, 300); */
          },
          onSpeechStart: () => {
            console.log('Speaking...');
            this.isUserSpeaking = true;
            this.scrollToBottom();
            if (this.audioPlayerQueue) {
              this.audioPlayerQueue.clear();
              this.audioPlayerQueue.pause();
            }
          },
          onFrameProcessed: (probs) => {

          },
        });

        this.microphone.start();
      }
    },
    async sendMessage() {
      if (this.message) {
        this.socket.emit('messageData', this.message);
        this.resetLastSpoke();
        this.message = '';
      }
    },
    resetLastSpoke() {
      this.lastSpokeCount = 0;
      this.lastSpoke = moment().format();
    },
    scrollToBottom() {
      if (!this.isSettingSocket && this.socket?.connected && this.activeServers.length > 0) {
        // Scroll to the bottom of the chat
        const scrollArea = this.$refs.scrollArea;
        const scrollTarget = scrollArea.getScrollTarget();
        const duration = 300; // ms - use 0 to instant scroll
        scrollArea.setScrollPosition('vertical', scrollTarget.scrollHeight, duration);
      }
    },
  },
  watch: {
    sessionMessages: {
      async handler(newItem, oldItem) {
        // this.scrollToBottom();

        if (this.activeServers.length === 0) {
          return;
        }

        if (newItem.length > oldItem.length) {
          let message = newItem[newItem.length - 1];

          if (message.type === 'user') {
            // User Message
            this.currentUserMessage = message;
          } else {
            // AI Message
            if (!this.audioPlayerQueue || !this.videoPlayerQueue) {
              // First boot
              this.audioPlayerQueue = new AudioPlayerQueue(this.$refs.audioQueue);
              this.videoPlayerQueue = new VideoPlayerQueue(this.$refs.videoQueue, this.sessionMessages.filter(m => m.type === 'ai'));
            } else {
              // Append message
              this.videoPlayerQueue.enqueue(message);
            }
          }
        }
      },
      deep: true,
    },
    isAudio: {
      handler(newItem, oldItem) {
        this.audioPlayerQueue.setMuted(!newItem);
        this.videoPlayerQueue.setMuted(!newItem);
      },
      deep: true,
    }
  }
};
</script>

<style scoped lang="scss">
.video-head {
  @media screen and (max-width: 599.99px) {
    // Move up on mobile
    top: 20vh;
  }
}
.video-container {
  position: relative;
  height: calc(100vw - 160px);
  width: calc(100vw - 160px);
  max-width: 300px;
  max-height: 300px;
}
.video-background,
.video-overlay {
  border-radius: $generic-border-radius;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-overlay {
  z-index: 2;
}

.video-background {
  z-index: 1;
}
</style>