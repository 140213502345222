<template>
  <section class="q-custom-content">
    <q-card class="rounded-borders q-ml-sm q-mr-sm q-mb-sm bg-glass">

      <div v-if="activeServers.length === 0">
        <p>No servers.</p>
      </div>

      <div v-else>
        <div class="relative-position">
          <!-- :style="`display:${isStreaming ? 'auto' : 'none'}`" -->
          <video id="screenVideo" autoplay></video>
          <div id="boundaryBox">
            <img id="streamVideo" />
          </div>
        </div>

        <q-item>
          <q-item-section v-if="isStreaming" >
            <q-btn @click="startLiveRequest" unelevated rounded color="accent" text-color="black" type="button">Update</q-btn>
          </q-item-section>
          <q-item-section>
            <q-btn @click="toggleStream(isStreaming)" unelevated rounded color="accent" text-color="black" type="button">{{ isStreaming ? 'Stop' : 'Start' }}</q-btn>
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section >
            <q-input outlined label="Prompt" type="text" v-model="prompt" placeholder="Enter a prompt"></q-input>
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section >
            <q-select v-model="checkpoint" :options="checkpoints" outlined label="Checkpoint"></q-select>
          </q-item-section>
          <q-item-section side>
            <q-input v-model="denoise" outlined label="Denoise" type="number" min="0.01" max="1" step="0.01" style="width:120px;" />
          </q-item-section>
          <q-item-section side>
            <q-input v-model="quality" outlined label="Quality" type="number" min="1" max="4" step="1" style="width:120px;" />
          </q-item-section>
        </q-item>

        <q-badge class="q-pa-sm q-ma-sm rounded-borders" v-if="requestId">{{requestId}}</q-badge>
      </div>
    </q-card>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { SendError, SendSuccess } from "@/helpers.js";

export default {
  head() {
    return {
      title: 'Home',
      titleTemplate: (title) => `${title} | Dimes.ai`,
      meta: [
      ],
    };
  },
  components: {
  },
  beforeMount() {
  },
  async mounted() {
    this.checkpoint = this.checkpoints[1];

    let position = { x: 0, y: 0 };

    const draggable = (el) => {
      interact(el)
        .resizable({
          // resize from all edges and corners
          edges: { left: true, right: true, bottom: true, top: true },
          listeners: {
            move (event) {
              const target = event.target;
              let x = (parseFloat(target.getAttribute('data-x')) || 0);
              let y = (parseFloat(target.getAttribute('data-y')) || 0);

              // update the element's style
              target.style.width = event.rect.width + 'px';
              target.style.height = event.rect.height + 'px';

              // translate when resizing from top or left edges
              x += event.deltaRect.left;
              y += event.deltaRect.top;

              target.style.transform = 'translate(' + x + 'px,' + y + 'px)';

              target.setAttribute('data-x', x);
              target.setAttribute('data-y', y);

              // target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height)
            }
          },
          modifiers: [
            // keep the edges inside the parent
            interact.modifiers.restrictEdges({
              outer: 'parent'
            }),
            // minimum size
            interact.modifiers.restrictSize({
              min: { width: 100, height: 100 }
            })
          ],

          inertia: true
        })
        .draggable({
          listeners: {
            move(event) {
              const target = event.target;
              // keep the dragged position in the data-x/data-y attributes
              let x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
              let y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

              // translate the element
              target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

              // update the posiion attributes
              target.setAttribute('data-x', x);
              target.setAttribute('data-y', y);
            }
          },
          inertia: true,
          modifiers: [
            interact.modifiers.restrictRect({
              restriction: 'parent',
              endOnly: true
            })
          ]
        });
    };

    // Draggable
    setTimeout(() => {
      let boundaryBox = document.getElementById('boundaryBox');
      draggable(boundaryBox);
    }, 100);

    this.streamVideo = document.getElementById("streamVideo");
  },
  async unmounted() {
    if (this.isStreaming && this.captureStream) {
      await this.toggleStream(true);
    }
  },
  data() {
    return {
      URL,
      isStreaming: false,
      streamVideo: null,
      requests: [],
      numOfRepeats: 1,
      prompt: 'woman',
      denoise: 0.35,
      quality: 2,
      checkpoint: '',
      prompts: [
        'woman'
      ],
      checkpoints: [
        'thisisreal_v20.safetensors',
        'realisticVisionV40_v40VAE.safetensors',
        'epicrealism_pureEvolutionV3.safetensors',
      ],
      canvasInterval: null,
      socket: null,
      readyState: '',
      requestId: null,
      webhookURL: null,
    };
  },
  computed: {
    ...mapState([
      'userProfile',
      'currentUser',
    ]),
    ...mapGetters([
      'activeServers',
    ]),
  },
  methods: {
    async startLiveRequest() {
      return new Promise(async (resolve, reject) => {
        try {
          if (this.activeServers.length === 0) {
            return SendError('No Servers Active');
          }

          const response = await this.$store.dispatch('sendLiveRequest', {
            serverId: this.activeServers[0].serverId,
            prompt: this.prompt,
            checkpoint: this.checkpoint,
            denoise: this.denoise,
            quality: this.quality,
          });

          this.requestId = response.requestId;

          if (!this.webhookURL) {
            this.webhookURL = response.webhookURL;
            await this.setupWebSocket();
          }

          resolve();
        } catch(err) {
          reject(err);
        }
      });
    },
    async deleteLiveRequest() {
      try {
        const response = await this.$store.dispatch('deleteLiveRequest');
        SendSuccess(response.message);
      } catch(err) {
        SendError(err);
      }
    },
    async setupWebSocket() {
      return new Promise(async (resolve, reject) => {
        try {
          let that = this;

          if (this.socket && this.socket.readyState === WebSocket.CLOSED) {
            console.log(`Socket CLOSED, Reopening`);
          } else if (this.socket && this.socket.readyState !== WebSocket.OPEN) {
            console.log(`Socket State: ${this.socket.readyState}`);
            return;
          } else if (this.socket) {
            console.log('setupWebSocket \n\n');
            // console.log('Closing Existing Websocket');
            // this.socket.close(1000);
          }

          // Establish a WebSocket connection to your server  // 'ws://10.0.0.4:5000'
          this.socket = new WebSocket(this.webhookURL); // Use the correct URL and port for your server

          this.socket.onopen = (event) => {
            console.log('WebSocket is open now.');
          };

          this.socket.onerror = (event) => {
            console.error('WebSocket error observed:', event);
          };

          this.socket.onclose = (event) => {
            console.log('WebSocket is closed now.', event);
          };

          this.socket.onmessage = (event) => {
            if (event.data instanceof Blob) {
              // console.log("Received blob data.");
              that.displayImageBlob(event.data);
            } else {
              console.log('Received non-blob data.');
            }
          };

          resolve();
        } catch(err) {
          reject(err);
        }
      });
    },
    async toggleStream(forceStop) {
      console.log('Toggling Stream...');

      let that = this;

      try {
        const video = document.getElementById('screenVideo');
        const boundaryBox = document.getElementById('boundaryBox');

        // Stop
        if (forceStop || (this.isStreaming && this.captureStream)) {
          // Clear Interval
          if (this.canvasInterval) {
            clearInterval(this.canvasInterval);
          }

          this.isStreaming = false;

          // Terminate Tracks
          if (this.captureStream) {
            let tracks = this.captureStream.getTracks();
            tracks.forEach((track) => {
              track.stop();
            });
          }

          // Close Socket
          if (this.socket) {
            this.socket.close(1000);
          }

          await this.deleteLiveRequest();
        } else {
          // Start

          this.captureStream = await navigator.mediaDevices.getDisplayMedia({
            video: true
          });

          await this.startLiveRequest();
          this.isStreaming = true;

          video.srcObject = this.captureStream;
          video.onloadedmetadata = () => {
            video.play();

            this.canvasInterval = setInterval(function() {
              if (that.isStreaming) {
                let videoRect = video.getBoundingClientRect();
                let rect = boundaryBox.getBoundingClientRect();

                let captureCanvas = document.createElement('canvas');
                let captureCtx = captureCanvas.getContext('2d');
                captureCanvas.width = rect.width;
                captureCanvas.height = rect.height;

                // Calculate the position and dimensions of the boundary box relative to the video
                let relativeLeft = (rect.left - videoRect.left) / videoRect.width * video.videoWidth;
                let relativeTop = (rect.top - videoRect.top) / videoRect.height * video.videoHeight;
                let relativeWidth = rect.width / videoRect.width * video.videoWidth;
                let relativeHeight = rect.height / videoRect.height * video.videoHeight;

                captureCtx.drawImage(video, relativeLeft, relativeTop, relativeWidth, relativeHeight, 0, 0, rect.width, rect.height);

                // Create a second canvas for resizing
                let resizeCanvas = document.createElement('canvas');
                resizeCanvas.width = 256;
                resizeCanvas.height = 256;
                let resizeCtx = resizeCanvas.getContext('2d');

                // Draw the captured image onto the resizing canvas
                resizeCtx.drawImage(captureCanvas, 0, 0, 256, 256);

                // Convert the resized image to a blob and send it
                resizeCanvas.toBlob((blob) => {
                  that.sendBlobToServer(blob);
                }, 'image/jpeg');
              }
            }, 1000/12); // 1000/24 fps
          };

          // Listen for the 'ended' event on the MediaStreamTrack
          this.captureStream.getVideoTracks()[0].onended = () => {
            this.toggleStream(true);
          };
        }
      } catch (err) {
        SendError(err);
      }
    },
    sendBlobToServer(blob) {
      if (this.socket && this.isStreaming) {
        if (this.socket?.readyState === WebSocket.OPEN) {
          this.socket.send(blob); // Send the blob data via the WebSocket
          // console.log("Blob sent to server.");
        } else if (this.socket.readyState === WebSocket.CONNECTING) {
          console.log('WebSocket is CONNECTING');
        } else if (this.socket.readyState === WebSocket.CLOSING) {
          console.log('WebSocket is CLOSING');
        } else if (this.socket.readyState === WebSocket.CLOSED) {
          console.log('WebSocket is CLOSED');
          // this.toggleStream(true)
          // console.log("WebSocket is reconnecting...");
          this.setupWebSocket();
        }
      }
    },
    displayImageBlob(blob) {
      if (this.isStreaming) {
        // Find the image element
        if (this.streamVideo && blob) {
          if (this.streamVideo.src) {
            URL.revokeObjectURL(this.streamVideo.src);
          }
          this.streamVideo.src = URL.createObjectURL(blob);
        } else {
          console.error('Element or blob is not available');
        }
      }
    },
  },
  watch: {
    socket: {
      handler(newItem, oldItem) {
        const states = {
          '-1': 'OFF',
          '0': 'CONNECTING',
          '1': 'OPEN',
          '2': 'CLOSING',
          '3': 'CLOSED'
        };
        const state = newItem.readyState ?? -1;
        this.readyState = states[state] || 'UNKNOWN';
      },
      deep: true,
    },
  }
};
</script>

<style lang="scss">
#screenVideo {
  position: relative;
  z-index: 1;
  width: 100%;
  height: auto;
  background: #000;
}
#streamVideo {
  width:100%;
  height:100%;
  opacity: 0.8;
}
#boundaryBox {
  top: 0;
  left: 0;
  width: 256px;
  height: 256px;
  position: absolute;
  z-index: 9;
  // border: 1px solid rgba(255, 255, 255, 0.39);
  touch-action: none;
  box-sizing: border-box;
}
</style>
