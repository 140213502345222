// main.js

import { createApp } from 'vue';
import MainApp from '@/App.vue';
import router from '@/router/router';
import store from '@/store/store';
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { Device } from '@capacitor/device';

// Head
import { createHead, VueHeadMixin } from '@unhead/vue';
const Head = createHead();

// Cookies
import VueCookies from 'vue-cookies';

// Vidstack
import 'vidstack/player';
import 'vidstack/player/layouts';
import 'vidstack/player/ui';

// Firebase
import { auth } from './firebaseConfig.js';

// Quasar
import {
  Quasar,
  Notify,
  Dialog,
} from 'quasar';
import iconSet from 'quasar/icon-set/mdi-v6';
import '@quasar/extras/mdi-v6/mdi-v6.css';

// SCSS
import '@/assets/scss/app.scss';

// SENTRY
import { GetRelease, GetVersion } from '@/helpers.js';
import * as Sentry from '@sentry/vue';

// APP
let VueApp;

const initApp = () => {
  console.log('Initializing App');

  // Get Device
  Device.getInfo().then(async device => {
    window.device = device;

    if (!VueApp) {
      VueApp = createApp(MainApp);

      VueApp.use(router)
        .mixin(VueHeadMixin)
        .use(Head)
        .use(store)
        .use(VueCookies)
        .use(Quasar, {
          iconSet,
          plugins: {
            Notify,
            Dialog,
          },
          config: {
            notify: { /* look at QuasarConfOptions from the API card */}
          },
        })
        .mount('#app');

      // Prototypes
      VueApp.config.globalProperties.window = window;

      Sentry.init({
        app: VueApp,
        dsn: 'https://91cce22d80ae7ec8f8c6e8621b26bdef@o4507269043453952.ingest.us.sentry.io/4507269046206464',
        integrations: [
          Sentry.browserTracingIntegration({ router }),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        async beforeSend(event, hint) {
          console.error(event);
          console.error(hint.originalException || hint.syntheticException);

          if (hint.originalException && hint.originalException.message && hint.originalException.message.toString() === 'Network Error') {
            await Dialog.create({
              title: '📡 No Connection',
              message: `We're having trouble connecting to the servers at the moment. Try refreshing the page and try again.`,
              html: true,
              fullWidth: false,
            }).onOk(async () => {
              // Refresh page if they hit continue
              location.reload();
            }).onCancel(() => {
            });
          }

          return event;
        },
        environment: import.meta.env.VITE_NODE_ENV,
        tracesSampleRate: 1,
        ignoreErrors: ['ResizeObserver loop limit exceeded'],
        release: GetVersion(), // Sourcemaps set on version, not release
      });

      if (Capacitor.isNativePlatform()) {
        await StatusBar.setStyle({ style: Style.Light });
        await StatusBar.hide();
        await SplashScreen.hide();
      }
    }
  });
};

auth.onAuthStateChanged(async (user) => {
  initApp();
});

// EOF